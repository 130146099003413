<template>
  <div class="success">
    <div class="center">
      <div class="center_text" v-if="ispc">
        <img src="@/assets/img/logo/success.png">
        <p>Your submission has been successful!</p>
        <div>
          <span>
            Thank you for submitting, we'll contact you as soon as possible!
          </span>
        </div>
        <el-button @click="backs">  
          BACK TO HOME
        </el-button>
      </div>
      <div class="center_text center_text1" v-else>
        <img src="@/assets/img/logo/success.png">
        <p>Your submission has been successful!</p>
        <div>
          <span>
            Thank you for submitting, we'll contact you as soon as possible!
          </span>
        </div>
        <el-button @click="backs">  
          BACK TO HOME
        </el-button>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header_white.vue"
import footers from "@/components/footers.vue"
export default {
  name: "success",
  data() {
    return {
      ispc:true
    };
  },
  mounted(){
    let w = document.documentElement.clientWidth || document.body.clientWidth;
    if(w <= 900){
      this.ispc = false
    }else{
      this.ispc = true
    }
  },
  components:{
    footers,
    headers
  },
  methods: {
    backs(){
      this.$router.push("/firstPage")
    },

  },
};
</script>


<style scoped lang="scss">
.success{
  width: 100%;
  height:100vh;
  .center{
    height:80%;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .center_text{
      width:50%;
      height:400px;
      padding-top:60px;
      text-align:center;
      div{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.87);
        span{
          display: inline-block;
          width:50%;
          line-height:30px;
        }
      }
      img{
        height:80px;
        width:80px;
      }
      p{
        margin-top:25px;
        margin-bottom:25px;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.87);
      }
      .el-button{
        width:220px;
        height:43px;
        margin-top:30px;
        background:#0D8AF2;
        color:#fff;
        border-radius:8px;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 600;
      }
      .el-button:hover{
        background:#0255D2;
      }
    }
    .center_text1{
      width: 80%;
      height: 800px;
      div{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 400;
        font-size: 67px;
        color: rgba(0, 0, 0, 0.87);
        span{
          display: inline-block;
          width:70%;
          line-height:67px;
        }
      }
      img{
        height:190px;
        width:190px;
      }
      p{
        font-size: 80px;
        margin-top:45px;
        margin-bottom:45px;
      }
      .el-button{
        width:420px;
        height:93px;
        margin-top:90px;
        font-size: 32px;
      }
    }
  }
}


</style>